/* Global styles to prevent text selection */
* {
	user-select: none !important;
	-webkit-user-select: none !important;
	-moz-user-select: none !important;
	-ms-user-select: none !important;
	-webkit-touch-callout: none !important;
}

/* Override for any input elements, textareas or content that should be selectable */
input,
textarea,
[contenteditable="true"] {
	user-select: text !important;
	-webkit-user-select: text !important;
	-moz-user-select: text !important;
	-ms-user-select: text !important;
}

.App {
	text-align: center;
	height: 100vh;
	background-color: #61dafb;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(360deg);
	}
}